@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.currentAccountContainer {
  border-radius: 12px;
  height: 76px;
  display: flex;
  align-items: center;
  padding: 0 27px;
  cursor: pointer;
  font-weight: 500;

  .content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .title {
      font-family: $globalFont;
      font-size: 16px;
    }

    .companyName {
      font-family: $globalFont;
      font-size: 16px;
      margin-top: 4px;
    }

    .subTitle {
      font-family: $globalFont;
      font-size: 14px;
      margin-top: 4px;
    }
  }
}
