@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.navigationContainer {
  display: flex;
  align-items: center;
  height: 100px;
  color: $accent;
  padding: 0 18px;
  right: 0;
  z-index: 6;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(3px);
  justify-content: center;
  box-shadow: rgba(34, 51, 84, 0.2) 0 2px 8px -3px, rgba(34, 51, 84, 0.1) 0px 5px 22px -4px;
  width: calc(100% - 36px);
  flex-wrap: wrap;

  &.connectProfileLabel {
    flex-wrap: wrap;
    height: 200px;
    justify-content: center;

    @include bp (xs) {
      flex-wrap: nowrap;
      height: 90px;
    }
    @include bp (m) {
      justify-content: flex-end;
    }
  }

  @include bp (xs) {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  @include bp (s) {
    height: 90px;
  }
  
  @include bp (l) {
    left: 290px;
    width: auto;
    padding: 0 18px;
    position: fixed;
  }

  .balanceInfo {
    font-family: $globalFont;
    color: rgb(88,89,91);
    text-align: center;
    font-size: 0.7rem;
    font-weight: 500;

    @include bp (xs) {
      padding-right: 24px;
    }

    .balance {
      color: rgb(79, 136, 144);
      font-weight: 500;
      font-size: 1.2rem;
    }

    .topUpButton {
      font-size: 0.7rem;
      height: 20px;
    }
  }

  .becomeOrganiserButton {
    opacity: 1 !important;
    width: 320px;

    @include bp (xs) {
      width: 200px;
    }

    &:hover {
      background-color: rgb(244, 246, 248);
    }
  }

  .connectAccountInfo {
    display: flex;
    min-width: 130px;
    flex-direction: column;

    @include bp (xs) {
      min-width: 130px;
    }

    @include bp (s) {
      min-width: 150px;
    }

    @include bp (m) {
      flex-direction: row;
    }

    a {
      @include bp (m) {
        margin-left: 30px;
      }
    }
  }

  .rightItems {
    display: contents;
  }
  
  .rightItems button {
      color: $accent;
  }
}

.mobile {
  top: 90px;
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 6;
  box-shadow: rgba(34, 51, 84, 0.2) 0 2px 8px -3px, rgba(34, 51, 84, 0.1) 0px 5px 22px -4px;
  font-size: 1rem;

  .navigationItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 0;
    margin-left: 7vw;
    width: 100%;

    .navigationItemIcon {
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
}

.popoverContent {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  border-radius: 8px;
  background-image: none;
  overflow: hidden auto;
  min-width: 200px;
  min-height: 16px;
  max-height: calc(100% - 32px);
  outline: 0;
  box-shadow: rgba(145, 158, 171, 0.24) 0 0 2px 0, rgba(145, 158, 171, 0.24) 0 12px 24px 0;
  max-width: 100%;
  opacity: 1;
  transform: none;
  transition: opacity 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 153ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .loginAs {
    color: $accent;
    padding: 12px 14px 10px;
    border-bottom: 1px solid $foreground-color;
    font-family: $globalFont;
    font-size: 16px;
    font-weight: 500;
  }

  .popoverList {
    list-style: none;
    margin: 0;
    padding: 8px 0;
    position: relative;
    outline: 0;

    .popoverListItem {
      list-style: none;
      background-color: transparent;
      outline: 0;
      border: 0;
      margin: 0;
      border-radius: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      line-height: 1.5;
      font-size: 1rem;
      font-family: $globalFont;
      font-weight: 300;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      text-decoration: none;
      padding: 6px 16px;
      box-sizing: border-box;
      white-space: nowrap;
      color: rgb(99, 115, 129);

      &:hover {
        background-color: rgb(244, 246, 248);
      }

      &.disabled {
        cursor: not-allowed;
        color: rgba(99, 115, 129, 0.5);

        &:hover {
          background-color: transparent;
        }
      }

      &.active {
        background-color: $accentLightest;
      }

      .popoverIcon {
        flex-shrink: 0;
        display: inline-flex;
        color: inherit;
        margin-right: 16px;
      }

      .popoverItemText {
        flex: 1 1 auto;
        min-width: 0;
      }
    }
  }
}