@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.layout {
  flex: 1 1 0;
  height: 100%;

  .withSidebar {
    position: relative;
    z-index: 5;
    display: block;
    flex: 1 1 0;

    @include bp (l) {
      padding-top: 80px;
      margin-left: 290px;
    }

    .content {
      display: block;
    }
  }
}

.moreInfo {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: inline;
}

.agreeButton {
  color: white;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 15px;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;

  @include bp (m) {
    flex-direction: row;
  }
}