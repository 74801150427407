@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

.creditPackagesModalContainer {
  font-family: $globalFont;
  color: $bright-color;
  background-image: linear-gradient(#075c68, #69c7bb);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;

  .closeButton {
    color: $bright-color;
    margin: 15px;
    align-self: flex-end;

    svg {
      transform: scale(1.5);
    }
  }

  .title {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 600;

    @include bp(s) {
      font-size: 1.25rem;
    }
  }

  .subTitle {
    @include bp(s) {
      font-size: 3rem;
    }
  }

  .creditPackageList {
    width: 90%;
    margin: 0 auto;
    display: grid;
    gap: 2rem;
    padding: 4rem 0;
    grid-auto-rows: 1fr;

    @include bp(s) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include bp(l) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include bp(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
