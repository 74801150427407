@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.popoverBaseContainer {
  color: $icon-color;
  display: flex;
  align-items: center;
  padding-left: 8px;
  max-height: 54px;
}

.popoverContainer {
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 5;
  padding: 7px;

  .popoverText {
    font-size: 12px;
    font-weight: 400;
    box-shadow: $popover-box-shadow 0px 3px 8px;
    padding: 12px;
    border-radius: 7px;
    margin: 0 7px 10px 7px;
    background-color: $icon-color;
    color: $bright-color;
    max-width: 180px;

    @include bp (xs) {
      max-width: 280px;
    }
  }
}
