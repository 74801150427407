@import "../../styles/abstracts/variables";

.loader {
  position: relative;
  height: 100%;
  left: 0;
  right: 0;

  &.fullScreen {
    position: absolute;
    height: calc(100% + 80px)
  }

  .loaderChildren {
    opacity: 0.2;
    pointer-events: none;
  }

  .loaderIcon {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $accent;
    font-size: 30px;
  }
}
