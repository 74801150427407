@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

.creditPackageItem {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  color: $bright-color;
  font-size: 1rem;
  font-family: $globalFont;

  .title {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .creditPackageBox {
    background-color: rgba(0, 0, 0, 0.4);
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    flex-grow: 1;

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
      border: 2px solid rgba(255, 255, 255, 0.5);
    }
  }

  .price {
    font-weight: 600;

    @include bp(s) {
      font-size: 3rem;
    }
  }

  .credits {
    font-size: 14px;
    text-transform: lowercase;
    border-bottom: 1px solid $bright-color;

    .bonusCredits {
      font-style: italic;
    }
  }

  .orderButton {
    display: flex;
    gap: 1rem;
    align-items: center;

    &:hover {
      background-color: #1fa0c4d6;
    }
  }
}
