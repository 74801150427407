$globalFont: 'Open Sans', sans-serif;
$globalHeadingFont: 'Roboto', sans-serif;
$titleFont: 'Baskerville', serif;
$globalLineHeight: 1.167;
$globalFontSizeBig: 2.0625rem;

$background-color: #FFFFFF;
$foreground-color: #F8F2E9;
$accent: #4F8890;
$accentMuted: #c5e3e9;
$accentLight: #58595B;
$accentLightest: #D6D9DB;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$success-text: rgb(8, 102, 13);
$success-background: rgb(255, 247, 205);
$success-icon: rgb(84, 214, 44);

$primary-button-background: rgb(0, 171, 85);
$primary-button-background-hover: rgb(0, 123, 85);
$accent-button-background-hover: rgba(79, 136, 144, 0.11);
$primary-button-shadow: rgba(0, 171, 85, 0.24) 0 8px 16px 0;

$label: rgba(34, 51, 84, 0.7);
$label-focused: #4F8890;
$label-error: rgb(255, 72, 66);

$input-border: rgba(88, 89, 91, 0.5);
$input-border-focused: #4F8890;
$input-border-error: rgb(255, 72, 66);
$input-error-text: rgb(255, 72, 66);

$primary-color: #E13119;
$primary-color-darker: #E35724;
$primary-color-bright: #ff7d58;
$primary-color-brighter: #ff7d58;
$primary-color-brightest: #ffb59f;

$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #FFFFFF;
$black-color: #000000;

$red-color: #E13119;
$red-color-darker: #e04321;
$red-color-brighter: #EFC5BD;

$warning-color: #F19A3E;
$warning-color-brighter: #F6C48D;
$warning-color-darker: #98540B;

$sidebar-color: #313638;
$navigation-color: #313638;

$icon-color: #787a7c;

$popover-box-shadow: rgba(0, 0, 0, 0.24);
$card-box-shadow: rgba(118, 124, 129, 0.24);

$grey: rgb(88, 89, 91);
$white: #FFFFFF;
$light-accent-background: #4F8890;
$not-active-tab: rgba(0, 0, 0, 0.6);
