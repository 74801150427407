@import "../../styles/abstracts/variables";
@import "../../styles/abstracts/mixins";

.textField {
  width: 100%;

  &.currencyInput {
    p {
      margin: 1px 0 0;
      line-height: 1.5;
      font-family: $globalFont;
      font-weight: 300;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 8px 14px 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  &.timeInput {
    :global(.MuiFormHelperText-sizeMedium) {
      position: absolute;
      top: 50px;
    }
  }

  .uploadIconContainer {
    color: $accent;
    display: flex;
    justify-content: center;
            
    > svg {
      font-size: 50px;
    }
  }

  label {
    font-family: $globalFont;
    color: $label;
    font-size: 14px;
    line-height: 1.4374em;
    font-weight: 300;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    position: absolute;

    &:global(.Mui-focused) {
      color: $label-focused;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 8px;
    font-size: 14px;
    font-family: $globalFont;
    font-weight: 300;
    line-height: 1.4375;

    input {
      font-family: $globalFont;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $globalFont;
    margin: 8px 14px 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }
}

.thumbnailContainer {
  .thumbnailField {
    border: 1px dashed rgba(145, 158, 171, 0.32);
    background-color: rgb(244, 246, 248);
    transition: padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    padding: 20px 20px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    text-align: left;
    overflow: hidden;
    display: flex;
    outline: none;

    .thumbnailTitle {
      margin-bottom: 20px;
      font-weight: 500;
      line-height: 1.5;
      font-size: 1.125rem;
      font-family: $globalHeadingFont;
    }

    .imagesContainer {
      display: flex;
      flex-flow: row wrap;
      gap: 16px;
      justify-content: center;

      .imageContainer {
        border: 3px solid transparent;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.selectedImage {
          border: 3px solid $accent;
        }

        &.pointerCursor {
          cursor: pointer;
        }

        img {
          width: 165px;
          height: 100px;
          object-fit: cover;
        }
      }

      .directUploadField {
        padding-top: 20px;
        padding-bottom: 30px;
        border: 1px dashed rgba(145, 158, 171, 0.32);
        width: 80%;
        margin: 10px auto;

        > input {
          display: none;
        }

        .uploadLabel {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .uploadIconContainer {
            color: $accent;
            
            > svg {
              font-size: 50px;
            }
          }

          .uploadLabelTitle {
            margin-top: 10px;
            margin-bottom: 5px;
            font-weight: 500;
            line-height: 1.5;
            font-size: 0.9rem;
            font-family: $globalHeadingFont;
          }

          .uploadError {
            color: $label-error;
            margin-bottom: 5px;
            font-size: 0.75rem;
            font-weight: 500;
          }
        
          p {
            font-family: $globalFont;
            margin: 3px 14px 0;
            font-size: 0.75rem;
        
            &:global(.Mui-error) {
              color: $input-error-text;
            }
          }
        }
      }
    }
  }

  .notSelected{
    display: flex;
    color: $red-color;
    font-size: 12px;
    margin: 0 14px;
  }
}

.fileField {
  border: 1px dashed rgba(145, 158, 171, 0.32);
  background-color: rgb(244, 246, 248);
  transition: padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: left;
  overflow: hidden;
  display: flex;
  outline: none;
  padding-top: 20px;

  input {
    display: none;
  }

  .imageContainer {
    border-top: 1px dashed rgba(145, 158, 171, 0.32);
    width: 100%;
    display: flex;
    padding-top: 30px;

    .images {
      display: flex;
      gap: 15px;
      align-items: flex-end;
      flex-wrap: wrap;
      margin: 20px auto;
      justify-content: center;
      text-align: center;

      .item {
        position: relative;
        max-height: 200px;
        border: 1px rgba(145, 158, 171, 0.32);
        margin-bottom: 15px;

        .fileTooBig {
          max-width: 170px;
          color: $red-color;
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          margin-top: -12px;
          line-height: 1rem;
        }

        .delete {
          position: absolute;
          right: 0;          
          background: #e74c3c;
          width: 25px;
          height: 25px;
          margin: 7px;
          color: white;
          border-radius: 5px;

          &:hover {
            cursor: pointer;
          }
        }

        .fileContainer {
          cursor: pointer;

          .fileTextContainer {
            display: flex;
            flex-direction: column;

            > img {
              width: 170px;
              height: 150px;
              object-fit: cover;
              border-radius: 8px;
            }
  
            > p {
              overflow: hidden;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  .labelContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
    
    .label {
      padding-bottom: 24px;
      text-align: center;
      
      .uploadFileIcon {
        color: $accent;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
                
        > svg {
          font-size: 50px;
        }
      }

      h5 {
        margin: 0 0 8px;
        font-weight: 500;
        line-height: 1.5;
        font-size: 1.125rem;
        font-family: $globalHeadingFont;
      }

      p {
        margin: 0;
        line-height: 1.57143;
        font-size: 0.875rem;
        font-family: $globalFont;
        font-weight: 300;
        color: rgb(0, 78, 146);
      }
      
      .notUploaded {
        color: $red-color;
        margin-top: 14px;
      }
    }
  }

  .filesError{
    display: flex;
    flex-wrap: wrap;
    color: $red-color;
    text-align: center;
    font-size: 14px;
    margin-top: 14px;
    margin-bottom: 14px;
  }
}

.loaderContainer {
  margin-top: 10px;

  > div {
    width: 170px;
    height: 200px;
  }
}

.switcherContainer, 
.checkboxContainer {
  margin-top: -20px;

  :global(.Mui-checked) {
    color: $accent !important;
  }

  :global(.MuiSwitch-track) {
    background-color: $accent !important;
  }

  :global(.MuiFormControlLabel-label) {
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: $globalFont;
    font-weight: 300;
  }

  :global(.Mui-disabled) {
    
    > svg {
      color: $accentLightest;
    }
  }
}
