@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.confirmationModal {
  :global(.MuiDialog-paperScrollPaper) {
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    margin: 32px;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 64px);
    max-width: 600px;
    width: 100%;
    box-shadow: rgba(145, 158, 171, 0.24) 0 0 4px 0, rgba(145, 158, 171, 0.24) 0 24px 48px 0;

    h2 {
      margin: 0;
      font-weight: 500;
      line-height: 1.55556;
      font-family: $globalHeadingFont;
      flex: 0 0 auto;
      padding: 24px 24px 0;
      font-size: 1.0625rem;
      text-align: center;

      @include bp(s) {
        font-size: 1.125rem;
      }
    }

    :global(.MuiDialogContent-root) {
      padding: 6px 24px 24px;
      flex: 1 1 auto;
      overflow-y: auto;
      border-top: 0;
      border-bottom: 0;
      display: flex;
      justify-content: center;

      :global(.MuiDialogContentText-root) {
        margin: 0;
        line-height: 1.5;
        font-size: 0.875rem;
        font-family: $globalFont;
        font-weight: 300;
        color: rgb(99, 115, 129);
      }
    }

    :global(.MuiDialogActions-root) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
      padding: 24px;
    }
  }

  &.isBig {
    max-width: 1920px;
    margin: 0 auto;

    :global(.MuiDialog-paperScrollPaper) {
      max-width: 90vw;
      margin: 0;
      border-radius: 0;
    }

    :global(.MuiDialogContent-root) {
      padding: 0;
    }
  }
}
