@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

.companySelectForm {
  width: 100%;
  display: flex;
  
  .form {
    width: 100%;
    display: flex;
    gap: 30px;
    flex-direction: column;

    > div {
      max-width: 600px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include bp(m) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
